import React from "react";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import { ThemeContext } from "../utils/themeContext";
import { useTranslation } from "react-i18next";

const Logo = styled(Image)`
  max-width: 162px;
  max-height: 100%;
`;

export const Loading = () => {
  const theme = React.useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center vw-100 vh-100 align-items-center">
      <div>
        <Logo src={theme.topBarIcon} />
        <p className="mt-4">{t("carregando")}...</p>
      </div>
    </div>
  );
};

export default Loading;

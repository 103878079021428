import React from "react";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { CheckoutPropType } from "../utils/CheckoutPropType";
import { translateBillingType } from "../utils/billing_types";
import { useTranslation } from "react-i18next";

const PriceContainer = styled.div`
  p {
    line-height: 1;
  }
`;

const parseDescription = (str = null) => {
  if (!str) {
    return null;
  }

  return str
    .toString()
    .split("\n")
    .map(i => <p key={i}>{i}</p>);
};

export const CheckoutDetails = ({ checkout = {} }) => {
  const { t } = useTranslation();
  const {
    max_installment_billing_type: maxInstallmentBillingType,
    cheapest_installment_billing_type: cheapestInstallmentBillingType,
  } = checkout;

  const maxInstallmentOption = maxInstallmentBillingType?.installments[0];
  const maxInstallment = maxInstallmentOption.installment;
  const cheapestInstallmentTotal =
    cheapestInstallmentBillingType?.installments[0].total;
  const billingTypes = checkout?.billing_types_options;
  const billingTypesTotal = billingTypes?.length;

  let initialText = t("parcele-em-ate-x-vezes-de", {
    maxInstallment,
  });

  let orText = "";

  // Caso não exista parcela à vista, exibir apenas a initialText
  if (cheapestInstallmentBillingType.installments[0].installment === 1) {
    orText = t("ou-rs-x-avista-no-x", {
      cheapestInstallmentTotal: formatCurrency(
        parseFloat(cheapestInstallmentTotal),
      ),
      billintType: translateBillingType(
        cheapestInstallmentBillingType.billing_type,
      ),
    });

    // Caso só exista uma forma de pagamento.
    if (billingTypesTotal === 1) {
      orText = t("no-tipo-de-pagamento-x", {
        paymentType: translateBillingType(
          cheapestInstallmentBillingType.billing_type,
        ),
      });
    }

    // Caso só exista um pagamento e apenas 1 parcela disponível
    if (billingTypesTotal === 1 && maxInstallmentOption.installment === 1) {
      initialText = ""
    }
  }

  return (
    <div>
      <h5 hidden>{t("product-details")}</h5>

      <Card body className="mx-3" hidden>
        {parseDescription(checkout?.description)}
      </Card>

      <PriceContainer>
        <p>
          {initialText}
          <strong className="fs-1 text-primary">
            R${" "}
            {formatCurrency(
              parseFloat(maxInstallmentOption?.installment_amount),
            )}
          </strong>
          {orText ? ` ${orText}` : ""}.
        </p>
      </PriceContainer>
    </div>
  );
};

CheckoutDetails.propTypes = {
  checkout: CheckoutPropType,
};

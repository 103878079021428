import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ThemeContext } from "../utils/themeContext";
import { BiCheckShield } from "react-icons/bi";
import { Trans, useTranslation } from "react-i18next";

const colorSuccess = "#16a34a";

export const CheckoutFooter = ({ checkout, organization }) => {
  const theme = React.useContext(ThemeContext);
  const { t } = useTranslation();

  const termsURL = checkout?.product?.user_terms_url || theme.termsURL;
  const privacyPolicyURL =
    checkout?.product?.privacy_url || theme.privacyPolicyURL;

  return (
    <div>
      <Container>
        <Row className="justify-content-md-center"></Row>
      </Container>

      <Col className="text-success text-center m-3 fs-6 lh-1">
        <BiCheckShield fontSize={40} color={colorSuccess} />{" "}
        {t("compra-cem-por-cento-segura")}.
      </Col>

      <p className="fs-6 m-0 text-center">
        <Trans
          i18nKey="termos-de-prosseguir-com-a-compra"
          values={{
            organization: organization?.name,
            termsURL,
            privacyPolicyURL,
          }}
          // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
          components={{ a: <a />, strong: <strong /> }}
        />
      </p>

      <p className="fs-6">
        {t("instrucoes-depois-do-pagamento")}
        <br />
        {theme.footerText}
      </p>

      <p>
        Zeus Pay Ltda © {new Date().getFullYear()} - {t("copyright")}
      </p>
    </div>
  );
};
